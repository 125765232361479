import _, { reject } from 'lodash';
const moment = require('moment');

function overview (token, key, scope, children, period, resolve, reject, complete) {
  console.log('dashboard.overview... ', key);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;

  this.$api.SSP
    .get('/dashboard/overview/?campaigns='+campaigns+'&dt_begin='+period[0]+'&dt_end='+period[1]+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.overview => ', response.data);

      resolve(key, response.data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.overview');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

function demographics (token, key, scope, children, period, resolve, reject, complete) {
  console.log('dashboard.demographics... ', key);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;
  // const ad = null;

  this.$api.SSP({
    method: 'GET',
    url: '/dashboard/demographics',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: {
      'campaigns': campaigns,
      'dt_begin': period[0],
      'dt_end': period[1],
      'perfil_publico_alvo':  {
        'gender': [],
        'age': [],
        'income_level': [] 
      }
    }
  })
  .then(response => {
    console.log('dashboard.demographics => ',response);
    // set demographics data
    resolve(key, response.data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && error.response.status==401){
      this.getout('dashboard.demographics');
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
};

function audience (token, key, scope, children, period, type, resolve, reject, complete) {
  console.log('dashboard.audience... ', key);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;

  this.$api.SSP
    .get('/dashboard/audience/?campaigns='+campaigns+'&dt_begin='+period[0]+'&dt_end='+period[1]+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.audience => ', response.data);

      const data = {
        coverage: response.data.cobertura_cidade,
        penetration: response.data.penetracao_cidade,
        frequency: response.data.frequencia_cidade,
      }

      resolve(key, data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.audience');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

function geofences (token, key, scope, children, period, type, resolve, reject, complete) {
  console.log('dashboard.geofences... ', key);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;

  this.$api.SSP
    .get('/dashboard/geofence/?campaigns='+campaigns+'&dt_begin='+period[0]+'&dt_end='+period[1]+'&group_by='+type+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.geofences => ', response.data);

      const data = _.map(response.data, g => {
        return {
          id: _.has(g, 'neighborhood_id') ? g.neighborhood_id : g.custom_id,
          title: _.has(g, 'custom_name') ? g.custom_name : '',
          url: _.has(g, 'url_custom') ? g.url_custom : null,
          type: _.has(g, 'type_code') ? g.type_code : null,
          use: _.has(g, 'use_code') ? g.use_code : null,
          ...g,
        }
      })
      resolve(key, data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.geofences');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

function report (token, key, scope, children, period, type, resolve, reject, complete) {
  console.log('dashboard.report... ', key);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;

  this.$api.SSP
    .get('/dashboard/report/?campaigns='+campaigns+'&dt_begin='+period[0]+'&dt_end='+period[1]+'&group_by='+type+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.report => ', response.data);

      let { overview: daily, report: hourly, geofences } = response.data;
      
      geofences = _.map(geofences, g => {
        return {
          id: _.has(g, 'neighborhood_id') ? g.neighborhood_id : g.custom_id,
          title: _.has(g, 'custom_name') ? g.custom_name : '',
          type: _.has(g, 'type_code') ? g.type_code : null,
          use: _.has(g, 'use_code') ? g.use_code : null,
          ...g,
        }
      })
      resolve(key, { daily, geofences, hourly });
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        // this.getout('dashboard.report');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

function timelapse (token, key, scope, children, period, resolve, reject, complete) {
  console.log('dashboard.timelapse... ', key, period);
  children = children!=null && children.length>0 ? children.join(',') : null;
  const campaign = scope;
  const campaigns = children != null ? campaign + ',' + children : campaign;

  this.$api.SSP
    .get('/dashboard/timelapse/?campaigns='+campaigns+'&dt_begin='+period[0]+'&dt_end='+period[1]+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.timelapse => ', response.data.length);

      const data = _.map(response.data, d => {
        return {
          day: d.day,
          ad_id: parseInt(d.ad_id),
          city_id: parseInt(d.city_id),
          lat: parseFloat(d.lat),
          log: parseFloat(d.log),
        }
      })
      resolve(key, data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.timelapse');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

function download (token, data, resolve, reject, complete) {
  console.log('dashboard.download... ', data);
  this.$api.SSP({
      url: '/dashboard/download',
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data
    })
    .then(response => {
      console.log('dashboard.download => ', response.data);

      resolve(response.data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.download');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    });
};

export {
  overview,
  demographics,
  audience,
  geofences,
  report,
  timelapse,
  download
}
