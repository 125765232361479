<template>
  <div 
    id="map"
  >
    <fullscreen
      ref="container"
      class="pa-0 fill-height d-flex flex-column align-stretch"
      @change="fullscreenChange"
    >
      <v-progress-linear
        :active="loading"
        indeterminate
        height="2"
        color="primary"
        class="loading faded"
      />
      <m-map
        :impressions="mapData"
        :cities="cities"
        :roles="user.roles"
        :layer-selection="false"
        :toolbar="false"
        ref="map"
        class="fill-height"
        @zoom-end="onZoomEnd"
      />
    </fullscreen>
  </div>
</template>

<style scoped>

  #map {
    padding: 0 !important;
  }

</style>

<script>
  import services from '@/services';
  import { timelapse as getTimelapse } from '@/api/dashboard';
  import { sync } from 'vuex-pathify'
  import numeral from 'numeral'
  const moment = require('moment');

  import { getGoogleMapsAPI as gmapApi } from 'gmap-vue';

  // Clients
  // const bichoBacanaPoints = () => import('@/assets/data/bichobacana-points.json');

  export default {
    name: 'Timelapse',

    props: {
      period: {
        type: Array,
        default: () => null
      },
      campaigns: {
        type: Object,
        default: () => null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      cities: {
        type: Object,
        default: () => {}
      },
      city: {
        type: Object,
        default: () => null
      },
      segment: {
        type: Number,
        default: 0
      },
      segments: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      stored: {
        type: Object,
        default: () => {}
      },
      auditor: {
        type: Boolean,
        default: false,
      },
      duration: {
        type: Array,
        default: () => null
      },
    },

    data: () => ({
      fullscreen: false,
      pipeline: {
        timelapse: { 
          get: 'getTimelapse',
          set: 'setTimelapse',
          key: null,
          period: null,
          loading: false,
          updated: false,
        },
      },
      points: {
        toggle: true,
        data: {},
        type: 'points',
        zoom: [],
        clients: {
          set: null,
          source: {}
        }
      },
      source: {
        prototype: {
          points: [],
        }
      },
      maxHeight: undefined,
      dev: process.env.NODE_ENV != 'production'
    }),

    components: {
      // SegmentFilter: () => import('@/components/SegmentFilter'),
      MMap: () => import('@/components/mMap'),
    },

    computed: {
      google: gmapApi,

      mapData () {
        const city = this.city;
        const points = this.points;
        const data = city!=null ? _.mapValues(this.points.data, hour => {
          return _.filter(hour, ['city', city.id]);
        }) : this.points.data;
        return { ...points, data };
      },

      loading () {
        return _.some(this.pipeline, 'loading');
      },
      updated () {
        return _.every(this.pipeline, 'updated');
      },

      dataKey () {
        return this.$route.path.toString();
      }

    },

    watch: {
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
        }
      },
    },

    methods: {
      ...services,
      getTimelapse,

      setTimelapse (key, source) {
        this.pipeline.timelapse.key = key;
        // if (_.has(this.source, key)) source = _.concat(this.source[key].points, source);
        // this.source[key].points = Object.freeze(source);

        if (_.isEmpty(source)) {
          this.loadNext();
          // this.toggleToast(
          //   true,
          //   'Não há dados de impressões para a ' + this.getDictionary('campaign') + ' nos últimos dias.',
          //   5000,
          //   true,
          // );
          return null;
        }
        
        // set map points data
        const $ = this;
        const ads = _.reduce([this.scope, ...this.children], (ads, c) => {
          const campaign = $.campaigns[c];
          return { ..._.mapValues(campaign.ads, ad => {
            return { title: ad.title, media: ad.media.url }
          }), ...ads };
        }, {});

        let timer = moment().valueOf();
        let t = 0;
        console.log('Processing timelapse...');

        let data = {}
        _.each(source, (p, i) => {
          const timestamp = moment.utc(p.day).local().format('YYYY-MM-DD HH:mm:ss');
          if (!_.has(data, timestamp)) data[timestamp] = [];
          if (p.lat!=0&&p.lat!=null&&!Number.isNaN(p.lat)) {
            data[timestamp].push(Object.freeze({
              id: _.has(p, 'id') ? p.id : i, 
              title: _.has(ads, p.ad_id) ? ads[p.ad_id].title : null,
              timestamp,
              city: parseInt(p.city_id),
              ad: p.ad_id,
              media: _.has(ads, p.ad_id) ? ads[p.ad_id].media : null,
              position: {
                lat: p.lat,
                lng: p.log
              },
              type: 'PTS',
            }));
          }
        });
        t = moment().valueOf()-timer-t;
        console.log('Processing timelapse checkpoint...', t);
        // const min = moment(_.last(_.keys(data).sort()).timestamp).subtract(15, 'days');
        // data = _.pickBy(data, (d,k) => moment(k).isAfter(min));

        // offset overlapped lat-log points
        data = _.mapValues(data, (d,k) => {
          const grouped = _.omitBy(_.reduce(d, (group, p, i) => {
            const key = p.position.lat+'|'+p.position.lng;
            group = _.assign(group, { [key]: _.has(group, key) ? { ...group[key], i: [i, ...group[key].i], count: group[key].i.length+1 } : { position: p.position, i: [i], count: 1 }})
            return group;
          }, {}), g => g.count==1);

          const offset = 10000;
          _.each(grouped, group => {
            const diff = [['lat', 'lng'], ['add', 'subtract']];
            let direction = [0,0];
            _.each(group.i, (p,x) => {
              const delta = (_.ceil((x+1)/4))/offset;
              let value = d[p].position[diff[0][direction[0]]];
              const way = diff[1][direction[1]];
              value = _[way](value, delta);
              d[p].position[diff[0][direction[0]]] = value;
              direction = [way ? 1 : 0, way&&direction[0] ? 0 : 1];
            })
          })
          return d;
        })
        t = moment().valueOf()-timer-t;
        console.log('Processing timelapse checkpoint...', t);

        data = Object.assign(this.points.data, data);
        this.points.data = Object.assign({}, data);
        // _.each(data, (d, k) => {
        //   this.$nextTick(() => {
        //     this.$set(this.points.data, k, d);
        //     // console.log('Timelapse size:', _.size(this.points.data));
        //   })
        // })
        t = moment().valueOf()-timer-t;
        console.log('Processed timelapse in ', t, moment().valueOf()-timer);

        // if (_.isNil(store)||store) this.storeData('timelapse', source);

        this.loadNext();
      },

      storeData (dimension, source) {
        console.log('store', dimension);
        const key = this.dataKey.toString();
        this.$emit('store-data', {
          key, 
          dimension,
          source
        })
      },

      updateView () {
        this.loadNext();
      },

      clearData () {
        console.log('cleaning up data...')
        _.each(this.pipeline, p => {
          p.updated = false;
        });
        this.points.data = {}
      },

      getStoredData (key, dimension) {
        return _.has(this.stored, dimension) && this.stored[dimension].key==key ? this.stored[dimension] : null;
      },

      loadNext () {
        const $ = this;
        const key = this.dataKey.toString();
        if (!_.has(this.source, key)) {
          this.source[key] = _.clone(this.source.prototype);
        }
        console.log('source', key);
        const source = this.source;
        _.each(this.pipeline, (p, k) => {
          const store = $.getStoredData(key, k);
          if (p.key!=key||!p.updated&&!p.loading) {
            // if (!_.isNil(store)) {
            //   const updated = moment().diff(moment(store.timestamp), 'minutes')<15;
            //   console.log('stored', k, updated);
            //   p.updated = updated;
            //   p.loading = !updated;
            //   $[p.set](key, store.source, false);
            //   if (updated) return false;
            // }
            if (_.isNil(p.period)) {
              const end = $.period[1];
              p.period = [moment(end).subtract(2, 'd'), moment(end).add(1, 'd')];
            }else{
              p.period = [moment(p.period[0]).subtract(3, 'd'), moment(p.period[1]).subtract(3, 'd')];
            }
            if (moment(p.period[1]).isBefore($.period[0])||moment($.period[1]).diff(p.period[1], 'd')>=15) {
              p.updated = true;
              return p.updated;
            }
            p.period = _.map(p.period, d => d.format('YYYY-MM-DD'));
            p.loading = true;
            $[p.get](
              $.user.auth.token,
              key,
              $.scope, 
              $.children, 
              p.period,
              (key, data) => {
                if (key==$.$route.path.toString()) {
                  p.loading = false;
                  $[p.set](key, data, p.period);
                }
              },
              (error) => {
                const msg = 'Aguardando resposta da nuvem...';
                setTimeout(($) => {
                  this.handleError(error, msg, [
                    true,
                    msg,
                    7000,
                    false
                  ]);
                  setTimeout(($) => $.loadNext(), 5000, $);
                }, 5000, this);
              },
              () => {
              }
            );
            p.updated = false;
            return p.updated;
          }else{
            p.updated = true;
            return p.updated;
          }
        });
      },

      toggleFullscreen () {
        this.$refs['container'].toggle();
      },

      fullscreenChange (b) {
        this.fullscreen = b;
      },

      onZoomEnd (layer) {
        this[layer].zoom = [];
      },

      onItemSelected (item) {
        console.log(item);
        this[this.layer].zoom = [item];
      },

      pause (delay) {
        return new Promise(resolve => setTimeout(resolve, delay));
      }
    },

    beforeDestroy () {
      this.points.data = {}
    }
  }
</script>
